// import api from '../api';
import apiNew from '../apiNew';

const fetchProcessingXLS = (params = {}, responseType = '', headers = {}) => {
  return apiNew.get(`/processing/xls`, {
    params: {
      ...params,
    },
    responseType: `${responseType}`,
    headers: {
      ...headers,
    },
  });
};
const fetchProcessingPDF = (params = {}, responseType = '', headers = {}) => {
  return apiNew.get(`/processing/pdf`, {
    params: {
      ...params,
    },
    responseType: `${responseType}`,
    headers: {
      ...headers,
    },
  });
};

const fetchProcessingReportsZip = (idHarvest, responseType = '', headers = {}) => {
  return apiNew.get(`/processing/${idHarvest}`, {
    responseType: `${responseType}`,
    headers: {
      ...headers,
    },
  });
};

const fetchProcessingReportsMilkZip = (params = {}, responseType = '', headers = {}) => {
  return apiNew.get(`/processing/milk`, {
    params: {
      ...params,
    },
    responseType: `${responseType}`,
    headers: {
      ...headers,
    },
  });
};

export {fetchProcessingXLS, fetchProcessingPDF, fetchProcessingReportsZip, fetchProcessingReportsMilkZip};
