/* eslint-disable no-unused-vars */
import React, {useState, useEffect} from 'react';
import fileDownload from 'js-file-download';
import FilterExportationMilk from '../../components/FilterForms/FilterExportationMilk';
import {formatFarmsToExport} from '../../utils/utils';
import {ExportationContainer, ButtonsContainer, ExportationButton} from './styled';
import {fetchProcessingReportsMilkZip} from '../../services/processing';

const ExportationMilk = () => {
  const XLSX = 'Exportar';
  const [openFilter, setOpenFilter] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [customer, setCustomer] = useState({});
  const [harvest, setHarvest] = useState({});
  const [farmsList, setFarmsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const farmListString = formatFarmsToExport(farmsList);
    setFarmsList(farmListString);
  }, [submitted]);// eslint-disable-line

  const fetchData = async (h) => {
    setIsLoading(true);
    try {
      const response = await fetchProcessingReportsMilkZip(
        {
          'harvestId': harvest.id,
          'farmId': `${farmsList}`,
        },
        'arraybuffer', // responseType,
        {
          Accept: 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9',
        },
      );

      fileDownload(
        response.data,
        `${`exportacao_relatorio_${new Intl.DateTimeFormat('pt-BR', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        }).format(new Date())}_.zip`}`,
      );
    } catch (e) {
      console.error(e);
    }
    setIsLoading(false);
  };

  return (
    <ExportationContainer>
      {openFilter && (
        <FilterExportationMilk
          setCustomer={setCustomer}
          setHarvest={setHarvest}
          setFarmsList={setFarmsList}
          setOpenFilter={setOpenFilter}
          setSubmitted={setSubmitted}
        />
      )}

      {submitted && (
        <ButtonsContainer>
          <ExportationButton onClick={(e) => fetchData(e, XLSX)}>{XLSX}</ExportationButton>
        </ButtonsContainer>
      )}
      
      {isLoading && (
        <div className="overlay">
          <div className="spinner" />
        </div>
      )}
    </ExportationContainer>
  );
};

export default ExportationMilk;
