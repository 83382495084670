import React from 'react';
import {Router, Switch} from 'react-router-dom';
import {GuardProvider, GuardedRoute} from 'react-router-guards';
import NotFound from './pages/NotFound/NotFound';
import Login from './pages/Login/Login';
import ForgotPassword from './pages/Login/ForgotPassword';
import UpdatePassword from './pages/Login/UpdatePassword';

import NoContent from './components/NoContent/NoContent';
import {history} from './utils/utils';
import InitialForm from './pages/InitialForm/InitialForm';
import PrimaryFilterForm from './components/FilterForms/Primary';
import Entries from './pages/Entries/Entries';
import EntriesDairy from './pages/Entries/EntriesDairy';
import Dashboards from './pages/Dashboards';
import GuardedSidebarRoute from './components/GuardedSidebarRoute/GuardedSidebarRoute';
import GuardedFilterRoute from './components/GuardedFilterRoute/GuardedFilterRoute';
import secureStorage from './utils/SecureStorage';
import {useModuleNameContextUpdate} from './utils/Providers/ModuleNameProvider';
import {ROLES, AUTH} from './utils/constants';
import {fetchUserMe} from './services/user';
import {DataContext} from './utils/DataProvider';

const Routes = () => {
  const updateModuleName = useModuleNameContextUpdate();
  const {dispatch} = React.useContext(DataContext);

  const requireLogin = async (to, from, next) => {
    let user = null;
    let token = null;
    if (to.match && to.match.params && to.match.params.token) {
      token = to.match.params.token;
      secureStorage.setItem('token', JSON.stringify(token));
      try {
        const localResponse = await fetchUserMe({token});
        const localUser = localResponse.data;
        dispatch({
          type: AUTH.ACTION.LOGIN,
          payload: {
            user: {
              id: localUser.id,
              user_type: localUser.user_type,
              email: localUser.email,
              name: localUser.name,
              only_diagnosis: localUser.only_diagnosis,
              profiles: localUser.profiles,
            },
            token,
          },
        });
        updateModuleName(to.meta.moduleName || '');
        next();
      } catch (error) {
        next.redirect('/login');
      }
    } else {
      try {
        user = secureStorage.getItem('user');
      } catch (error) {
        next.redirect('/login');
      }
      try {
        token = secureStorage.getItem('token');
      } catch (error) {
        token = null;
        console.error('Route Error', error);
      }
      const isStrictedRoute = !!to.meta.allowed;
      const hasAccess = isStrictedRoute ? to.meta.allowed.includes(JSON.parse(user).user_type) : true;

      if (user && token) {
        if (hasAccess) {
          updateModuleName(to.meta.moduleName || '');
          next();
        } else {
          next.redirect(from.history.goBack());
        }
      }
      next.redirect('/login');
    }
  };

  return (
    <Router history={history}>
      <GuardProvider>
        <Switch>
          <GuardedRoute path="/login" exact component={Login} />
          <GuardedRoute path="/forgot-password" exact component={ForgotPassword} />
          <GuardedRoute path="/update-password/:username" exact component={UpdatePassword} />

          <GuardProvider guards={[requireLogin]}>
            <Switch>
              <GuardedSidebarRoute path="/" exact component={NoContent} />
              <GuardedSidebarRoute path="/filter-form" exact component={PrimaryFilterForm} />
              <GuardedSidebarRoute path="/initial-form" exact component={InitialForm} />
              <GuardedFilterRoute
                path="/lancamentos"
                meta={{
                  moduleName: 'Lançamentos',
                }}
                component={Entries}
              />
              <GuardedFilterRoute path="/diagnostico" meta={{moduleName: 'Diagnóstico'}} component={Entries} />
              <GuardedFilterRoute path="/corte/lancamentos" meta={{moduleName: 'Lançamentos'}} component={Entries} />
              <GuardedSidebarRoute
                path="/cadastros"
                meta={{
                  moduleName: 'Cadastros',
                  allowed: [ROLES.SUPER_ADMIN, ROLES.ADMIN],
                }}
                component={Entries}
              />
              <GuardedSidebarRoute path="/corte/dashboards" meta={{moduleName: 'Painel de consulta'}} component={Entries} />
              <GuardedSidebarRoute path="/corte/configuracao" meta={{moduleName: 'Configuração'}} component={Entries} />
              <GuardedSidebarRoute path="/corte/exportacao" meta={{moduleName: 'Exportação'}} component={Entries} />
              <GuardedSidebarRoute path="/corte/import" meta={{moduleName: 'Importação'}} component={Entries} />
              <GuardedSidebarRoute path="/corte/processamento" meta={{moduleName: 'Processmento'}} component={Entries} />
              <GuardedSidebarRoute path="/corte/benchmark" meta={{moduleName: 'Processmento'}} component={Entries} />
              <GuardedSidebarRoute path="/corte/comunicacao" meta={{moduleName: 'Comunicação'}} component={Entries} />
              <GuardedSidebarRoute path="/corte/faq" meta={{moduleName: 'Faq'}} component={Entries} />
              <GuardedSidebarRoute path="/corte/faq-categoria/:id" meta={{moduleName: 'Faq'}} component={Entries} />
              <GuardedSidebarRoute path="/corte/checklist" meta={{moduleName: 'Checklist'}} component={Entries} />
              <GuardedSidebarRoute path="/mobile/dashboards/:token" meta={{moduleName: 'Painel de consultas'}} component={Dashboards} />
              {/* MILK SESSION */}
              <GuardedFilterRoute path="/leite/lancamentos" meta={{moduleName: 'Lançamentos'}} component={EntriesDairy} />
              <GuardedSidebarRoute path="/leite/relatorios" meta={{moduleName: 'Lançamentos'}} component={EntriesDairy} />
              <GuardedSidebarRoute path="/leite/benchmark" meta={{moduleName: 'Benchmarking'}} component={EntriesDairy} />
              <GuardedSidebarRoute path="/leite/exportacao" meta={{moduleName: 'Exportação'}} component={EntriesDairy} />
              <GuardedSidebarRoute path="/leite/comunicacao" meta={{moduleName: 'Comunicação'}} component={EntriesDairy} />
              <GuardedSidebarRoute path="/leite/faq" meta={{moduleName: 'Faq'}} component={EntriesDairy} />
              <GuardedSidebarRoute path="/leite/faq-categoria/:id" meta={{moduleName: 'Faq'}} component={EntriesDairy} />
            </Switch>
          </GuardProvider>

          <GuardedRoute path="*" component={NotFound} />
        </Switch>
      </GuardProvider>
    </Router>
  );
};

export default Routes;
