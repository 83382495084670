/* eslint-disable no-unused-vars */
import React, {useState, useEffect} from 'react';
import fileDownload from 'js-file-download';
import {CircularProgress} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import {ExportationContainer, ButtonsContainer, ExportationButton} from './styled';
import {formatFarmsToExport} from '../../utils/utils';
import {fetchProcessingXLS, fetchProcessingPDF} from '../../services/processing';
import FilterProcessing from '../../components/FilterForms/FilterProcessing';

const Processing = () => {
  const XLSX = 'XLS';
  const PDF = 'PDF';
  const CSV = 'CSV';
  const [openFilter, setOpenFilter] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [customer, setCustomer] = useState({});
  const [harvest, setHarvest] = useState({});
  const [farmsList, setFarmsList] = useState([]);
  const [initialDate, setInitialDate] = useState('');
  const [finalDate, setFinalDate] = useState('');
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    const farmListString = formatFarmsToExport(farmsList);
    setFarmsList(farmListString);
  }, [submitted]);// eslint-disable-line

  const fetchData = async (event, fileType) => {
    event.preventDefault();
    setLoading(true);
    try {
      let endpoint;
      let extension;
      switch (fileType) {
        case XLSX:
          endpoint = fetchProcessingXLS;
          extension = 'xls';
          break;
        case PDF:
          endpoint = fetchProcessingPDF;
          extension = 'pdf';
          break;

        default:
          return;
      }

      // const initDate = `${initialDate.year}-${
      //   initialDate.month < 10
      //     ? `0${parseInt(initialDate.month, 10) + 1}`
      //     : `${parseInt(initialDate.month, 10) + 1}`
      // }`;
      // const finishDate = `${finalDate.year}-${
      //   finalDate.month < 10
      //     ? `0${parseInt(finalDate.month, 10) + 1}`
      //     : `${parseInt(finalDate.month, 10) + 1}`
      // }`;

      const response = await endpoint(
        {
          'wherein[harvest.id]': harvest.id,
          'wherein[customer.id]': customer.id,
          'wherein[farm.id]': `${farmsList}`,
          // initialDate: initDate,
          // endDate: finishDate,
        },
        'arraybuffer', // responseType,
        {
          Accept: 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9',
        },
      );
      if (extension === 'pdf') {
        // let nameFile = response.headers['content-disposition'].split('filename=')[1];
        // nameFile = nameFile?.replace(/"/g, '');
        fileDownload(
          response.data,
          `${`relatorio_processamento_${new Intl.DateTimeFormat('pt-BR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          }).format(new Date())}_.pdf`}`,
        );
      } else {
        fileDownload(
          response.data,
          `${`relatorio_processamento_${new Intl.DateTimeFormat('pt-BR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          }).format(new Date())}_.xlsx`}`,
        );
      }
    } catch (e) {
      setOpenDialog(true);
      console.error(e);
    }
    setLoading(false);
  };

  return (
    <ExportationContainer>
      {openFilter && (
        <FilterProcessing
          setCustomer={setCustomer}
          setHarvest={setHarvest}
          setFarmsList={setFarmsList}
          setInitialDate={setInitialDate}
          setFinalDate={setFinalDate}
          setOpenFilter={setOpenFilter}
          setSubmitted={setSubmitted}
        />
      )}

      {submitted && (
        <ButtonsContainer>
          <ExportationButton onClick={(e) => fetchData(e, XLSX)}>{XLSX}</ExportationButton>
          <ExportationButton onClick={(e) => fetchData(e, PDF)}>{PDF}</ExportationButton>
        </ButtonsContainer>
      )}
      {loading ? <CircularProgress color="inherit" size={20} /> : null}
      <Dialog open={!!openDialog} onClose={() => setOpen(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          <i className="fas fa-engine-warning" />
          Atenção
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Verifique o cadastro da sua fazenda para emissão do relatório</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenDialog(false);
            }}
            color="primary"
            autoFocus>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </ExportationContainer>
  );
};

export default Processing;
