import apiNew from '../apiNew';

const fetchFinancials = (params = {}, environment = 'BEEF_CATTLE') => {
  return apiNew.get(`/financials`, {
    params: {environment, ...params},
  });
};

const fetchFinancialsbyId = (id, params = {}, environment = 'BEEF_CATTLE') => {
  return apiNew.get(`/financials/${id}`, {
    params: {environment, ...params},
  });
};
const changeFinancialsValidate = (type, body = {}) => {
  return apiNew.put(`/financials/validate?type=${type}`, body);
};
const changeFinancialsbyId = (id, body = {}, environment = 'BEEF_CATTLE') => {
  return apiNew.put(`/financials/${id}`, {environment, ...body});
};
const createFinancials = (body = {}, environment = 'BEEF_CATTLE') => {
  return apiNew.post(`/financials`, {environment, ...body});
};
const deleteFinancials = (id) => {
  return apiNew.delete(`/financials/${id}`);
};

export {fetchFinancials, fetchFinancialsbyId, changeFinancialsbyId, deleteFinancials, createFinancials, changeFinancialsValidate};
