import styled from 'styled-components';

export const ExportationContainer = styled.div`
  align-itens: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

export const ButtonsContainer = styled.div`
  align-itens: center;
  display: flex;
  height: 100%;
  justify-content: space-around;
  max-width: 200px;
  width: 100%;
`;

export const ExportationButton = styled.button`
  align-items: center;
  border-radius: 3px;
  display: flex;
  height: 30px;
  justify-content: center;
  width: 85px;
`;
