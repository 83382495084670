import React, {useContext, useEffect, useState} from 'react';
import {DataContext} from '../../../../../utils/DataProvider';
import {IncomeServices} from '../services/IncomeServices';
import {createOrUpdateDataFull} from '../../../../../utils/utils';
import {DatagridActionsHelper} from '../../../utils/datagridActionsHelper';
import DataGrid from '../../../../../components/DataGrid';
import {DatePickerHelper} from '../../../utils/datePickerHelper';
import {ServicesHelper} from '../../services/ServicesHelper';

const Income = () => {
  const {state, stateFetchParams, stateMessage, filterParams} = useContext(DataContext);
  const [data, setData] = useState(null);
  const [total, setTotal] = useState(0);
  const [reset, setReset] = useState(false);
  const [costCenterList, setCostCentersList] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const response = await IncomeServices.list({
          ...stateFetchParams,
          ...filterParams,
          type: 'RECEITAS',
        });

        const {data: currentData} = response;
        setData(currentData.resultList);
        setTotal(currentData.size);

        const costCenterDat = await ServicesHelper.list('/cost_centers', {
          environment: 'DAIRY_CATTLE',
          type: 'RECEITAS',
        });

        // eslint-disable-next-line
        let costCenterData = null;

        // TODO alteracao do danilo para o leite
        setCostCentersList(
          costCenterDat.data.resultList.filter((it) => it.title && !['compra de rebanho', 'venda de animais'].includes(it.title.toLowerCase())),
        );
      } catch (e) {
        console.error(e);
      }
    })();
  }, [JSON.stringify(stateFetchParams).toLowerCase(), filterParams, reset]);// eslint-disable-line

  const validationFindData = (temp) => {
    const validates = temp.map((item) => item.validated);
    return validates.length && validates.includes(true);
  };

  const handleValidateCallback = async (rows, field, checked) => {
    const promises = [];
    rows.forEach((row) => {
      if (row.notCreated) return;

      promises.push(createOrUpdateDataFull({id: row.id, validated: checked}, state, IncomeServices.post, IncomeServices.put));
    });

    const response = await Promise.all(promises);
    setReset(!reset);
    return response;
  };

  const actions = DatagridActionsHelper.actions({
    handleDelete: async (value) => {
      try {
        const response = await IncomeServices.delete(value.id);
        setReset(!reset);
        return response;
      } catch (error) {
        return error;
      }
    },
  });

  const columns = [
    {
      key: 'date',
      field: 'date',
      name: 'Data',
      required: true,
      textAlign: 'Center',
      width: '15%',
      dateView: ['month'],
      dateFormat: 'MM/yyyy',
      ...DatePickerHelper.dailyAndMonth(state),
    },
    {
      key: 'cost_center',
      valueKey: 'cost_center.title',
      field: 'cost_center',
      required: true,
      name: 'Itens de Receita',
      textAlign: 'center',
      width: '25%',
      editType: 'dropdownedit',
      optionsFetch: async () => {
        try {
          const response = await ServicesHelper.loadCategory('/cost_centers', {
            environment: 'DAIRY_CATTLE',
            type: 'RECEITAS',
          });
          if (Array.isArray(response)) {
            const filteredList = response.filter((it) => {
              return it.label && !['compra de rebanho', 'venda de animais'].includes(it.label.toLowerCase());
            });
            return filteredList;
          }
          return [];
        } catch (error) {
          console.error('Error fetching cost centers:', error);
          return [];
        }
      },
    },
    {
      key: 'description',
      valueKey: 'description',
      field: 'description',
      required: true,
      name: 'Descrição',
      textAlign: 'center',
      width: state?.user?.user_type === 'CUSTOMER' ? '30%' : '20%',
      editType: 'textedit',
    },
    {
      key: 'quantity',
      field: 'quantity',
      name: 'Quantidade',
      width: '10%',
      textAlign: 'Center',
      required: true,
      editType: 'numericedit',
      extraParams: {
        minValue: 1,
      },
    },
    {
      key: 'value',
      field: 'value',
      name: 'Valor Total',
      width: '10%',
      textAlign: 'Center',
      editType: 'moneyedit',
      // dependencies: ['unit_value'],
      extraParams: {
        minValue: 1,
        formatedValue: (col) => {
          if (
            costCenterList?.find((item) => item.id === col?.data?.cost_center?.value)?.only_quantity ||
            (col?.mode === 'NEW_LINE' && col.data.aux === 'unit_value') ||
            (col.mode === 'EDIT' && col?.data?.status)
          ) {
            return parseFloat(col?.data?.unit_value) * parseFloat(col?.data?.quantity) || null;
          }
          return col?.data?.value;
        },
      },
      required: (col) => {
        if (
          costCenterList?.find((item) => item.id === col?.data?.cost_center?.value)?.only_quantity ||
          (col?.mode === 'NEW_LINE' && col?.data?.aux === 'unit_value') ||
          (col.mode === 'EDIT' && col?.data?.status)
        ) {
          return false;
        }
        return true;
      },
      disabledEdit: (col) => {
        if (col?.data?.value?.length > 0 && !col.data.aux) {
          col.data.aux = 'value';
        }
        if (col?.data?.value?.length === 0 && col.data.aux === 'value') {
          col.data.aux = null;
        }
        if (
          costCenterList?.find((item) => item.id === col?.data?.cost_center?.value || item.id === col?.data?.cost_center?.id)?.only_quantity ||
          (col?.mode === 'NEW_LINE' && col?.data?.aux === 'unit_value') ||
          (col.mode === 'EDIT' && col?.data?.status)
        ) {
          return true;
        }
        return false;
      },
    },
    {
      key: 'unit_value',
      field: 'unit_value',
      name: 'Valor Unitário',
      width: '10%',
      textAlign: 'Center',
      editType: 'moneyedit',
      // dependencies: ['value'],
      extraParams: {
        minValue: 1,
        formatedValue: (col) => {
          if (
            costCenterList?.find((item) => item.id === col?.data?.cost_center?.value)?.only_quantity ||
            (col?.mode === 'NEW_LINE' && col.data.aux === 'value') ||
            (col.mode === 'EDIT' && !col?.data?.status)
          ) {
            return parseFloat(col?.data?.value) / parseFloat(col?.data?.quantity) || null;
          }
          return col?.data?.unit_value;
        },
      },
      required: (col) => {
        if (
          costCenterList?.find((item) => item.id === col?.data?.cost_center?.value)?.only_quantity ||
          (col?.mode === 'NEW_LINE' && col?.data?.aux === 'value') ||
          (col.mode === 'EDIT' && !col?.data?.status)
        ) {
          return false;
        }
        return true;
      },
      disabledEdit: (col) => {
        if (col?.data?.unit_value?.length > 0 && !col.data.aux) {
          col.data.aux = 'unit_value';
        }
        if (col?.data?.unit_value?.length === 0 && col.data.aux === 'unit_value') {
          col.data.aux = null;
        }
        if (
          costCenterList?.find((item) => item.id === col?.data?.cost_center?.value || item.id === col?.data?.cost_center?.id)?.only_quantity ||
          (col?.mode === 'NEW_LINE' && col?.data?.aux === 'value') ||
          (col.mode === 'EDIT' && !col?.data?.status)
        ) {
          return true;
        }
        return false;
      },
    },
    {
      key: 'validated',
      field: 'validated',
      name: 'Validar',
      width: '10%',
      textAlign: 'Center',
      editType: 'booleanedit',
      validationHeader: {showLabel: false, validationFindData},
    },
  ];

  return (
    <>
      {stateMessage}
      {data && costCenterList && (
        <DataGrid
          onChange={async (inputs) => {
            if (!inputs.date) {
              const date = DatePickerHelper.newDate();
              Object.assign(inputs, {date});
            }

            if (inputs?.validated == null) Object.assign(inputs, {validated: false});

            Object.assign(inputs, {status: false});
            inputs = {
              ...inputs,
              ...(inputs.quantity && {quantity: parseFloat(inputs.quantity)}),
              ...(inputs.value && {value: parseFloat(inputs.value)}),
              ...(inputs.unit_value && {unit_value: parseFloat(inputs.unit_value)}),
              type: 'RECEITAS',
            };

            if (inputs.value) {
              inputs.unit_value = inputs.value / inputs.quantity || 1;
            } else if (inputs.unit_value) {
              inputs.value = inputs.unit_value * inputs.quantity || 1;
            }

            if (inputs.aux === 'unit_value') {
              inputs.status = true;
            } else {
              inputs.status = false;
            }
            delete inputs.aux;
            try {
              const res = await createOrUpdateDataFull(inputs, state, IncomeServices.post, IncomeServices.put);
              setReset(!reset);
              return res;
            } catch (error) {
              return error;
            }
          }}
          validateCallback={handleValidateCallback}
          header={columns}
          data={data}
          width="inherit"
          keyProp="key"
          keyValueProp="valueKey"
          titleProp="name"
          editable=""
          actions={actions}
          totalItems={total}
        />
      )}
    </>
  );
};

export default Income;
