import React from 'react';
import {GuardedRoute} from 'react-router-guards';
import './style.scss';
import Financials from '../DairyPages/Releases/Financials/Financials';
import Moves from '../DairyPages/Releases/Moves/Moves';
import ZooData from '../DairyPages/Releases/ZooData/ZooData';
import Livestock from '../DairyPages/Releases/Livestock/Livestock';
import GeneralIndicators from '../DairyPages/Reports/GeneralIndicators/GeneralIndicators';
import Abstract from '../DairyPages/Reports/Abstract/Abstract';
import CashFlow from '../DairyPages/Reports/CashFlow/CashFlow';
// import IndividualReport from '../DairyPages/Reports/IndividualReport/IndividualReport';
import MonthlyReport from '../DairyPages/Reports/MonthlyReport/MonthlyReport';
import logoLancamentos from '../../assets/images/logo_lancamentos.png';
import {FarmDetails, Teams} from '../DairyPages/Releases';
import Agriculture from '../DairyPages/Releases/Agriculture/Agriculture';
import RegisterAgriculture from '../DairyPages/Releases/Agriculture/modules/RegisterPage';
import Communication from '../Communication';
import Faq from '../Communication/QuestionFaq/faq';
import QuestionFaq from '../Communication/QuestionFaq/questionFaq';
import Benchmarking from '../Benchmark';
import Quadrant from '../DairyPages/Reports/quadrant/quadrant';
import FilterExportationQuadrant from '../DairyPages/Reports/quadrant/filter';
import IndividualReport from '../DairyPages/Reports/MonthlyReport/individualReport';
import ExportationMilk from '../ExportationMilk';

const logoMap = {
  '/lancamentos': logoLancamentos,
};

const EntriesDairy = ({match}) => {
  return (
    <>
      <div className={`entries-content ${match.url.replace('/', '')}`}>
        <div className="control-pane" style={{height: '100%', overflow: 'auto'}}>
          <div className="control-section">
            <div className="col-md-12 p-0 h-100">
              {logoMap[window.location.pathname] && (
                <div className="entries-logo-container">
                  <img className="entries-logo" src={logoMap[window.location.pathname]} alt="" />
                </div>
              )}
              <GuardedRoute path={`${match.url}/agricultura-cadastro`} meta={{moduleName: 'Agricultura'}} component={RegisterAgriculture} />
              <GuardedRoute path={`${match.url}/agricultura-cadastro-view/:id`} meta={{moduleName: 'Agricultura'}} component={RegisterAgriculture} />
              <GuardedRoute path={`${match.url}/agricultura`} meta={{moduleName: 'Agricultura'}} component={Agriculture} />
              <GuardedRoute path={`${match.url}/financeiro`} meta={{moduleName: 'Financeiro'}} component={Financials} />
              <GuardedRoute path={`${match.url}/caracteriza-fazenda`} meta={{moduleName: 'Caracteriza Fazenda'}} component={FarmDetails} />
              <GuardedRoute path={`${match.url}/equipe`} meta={{moduleName: 'Equipe'}} component={Teams} />
              <GuardedRoute path={`${match.url}/pecuaria`} meta={{moduleName: 'Pecuária'}} component={Livestock} />
              <GuardedRoute path={`${match.url}/movimentacoes`} meta={{moduleName: 'Movimentações'}} component={Moves} />
              <GuardedRoute path={`${match.url}/zootecnicos`} meta={{moduleName: 'Dados Zootécnicos'}} component={ZooData} />
              <GuardedRoute path={`${match.url}/indicadores-gerais`} meta={{moduleName: 'Indicadores Gerais'}} component={GeneralIndicators} />
              <GuardedRoute path={`${match.url}/resumo`} meta={{moduleName: 'Resumo'}} component={Abstract} />
              <GuardedRoute path={`${match.url}/fluxo-caixa`} meta={{moduleName: 'Fluxo de Caixa'}} component={CashFlow} />
              <GuardedRoute path={`${match.url}/individual`} meta={{moduleName: 'Relatório Individual'}} component={IndividualReport} />
              <GuardedRoute path={`${match.url}/mensal`} meta={{moduleName: 'Relatório Geral'}} component={MonthlyReport} />
              <GuardedRoute path={`${match.url}/quadrante`} meta={{moduleName: 'Quadrante'}} component={Quadrant} />
              <GuardedRoute path={`${match.url}/filtro-quadrante`} meta={{moduleName: 'Quadrante'}} component={FilterExportationQuadrant} />
              <GuardedRoute path="/leite/benchmark" meta={{moduleName: 'Benchmark'}} component={Benchmarking} />
              <GuardedRoute path="/leite/exportacao" meta={{moduleName: 'Exportação'}} component={ExportationMilk} />
              <GuardedRoute path="/leite/comunicacao" meta={{moduleName: 'Comunicação'}} component={Communication} />
              {/* <GuardedRoute path="/leite/comunicacao" meta={{moduleName: 'Comunicação'}} component={Communication} /> */}
              <GuardedRoute path="/leite/faq" meta={{moduleName: 'Faq'}} component={Faq} />
              <GuardedRoute path="/leite/faq-categoria/:id" meta={{moduleName: 'Faq'}} component={QuestionFaq} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default EntriesDairy;
