import api from '../api';
// import apiNew from '../apiNew';

const fetchFarmsDetails = (params = {}, environment = 'BEEF_CATTLE') => {
  return api.get(`/farms_details`, {
    params: {
      environment,
      ...params,
    },
  });
};

const fetchFarmsDetailsbyCustomerId = (id, params = {}, environment) => {
  return fetchFarmsDetails({
    'wherein[customer.id]': id,
    environment,
    ...params,
  });
};

const fetchFarmsDetailsbyCustomerIdAndByHarvestId = (customerId, harvestId, params = {}, environment) => {
  return fetchFarmsDetails({
    'wherein[customer.id]': customerId,
    'wherein[harvest.id]': harvestId,
    ...environment,
    ...params,
  });
};

const changeFarmsDetailsbyId = (id, body = {}, environment = 'BEEF_CATTLE') => {
  const {
    // eslint-disable-next-line camelcase
    list_category_comercial_feed,
    // eslint-disable-next-line camelcase
    list_category_core_used,
    // eslint-disable-next-line camelcase
    list_category_main_bulk_used,
    ...rest
  } = body;

  const data = {
    environment,
    ...rest,
  };
  return api.put(`/farms_details/${id}`, data);
};

const registerFarmsDetails = (body = {}, environment = 'BEEF_CATTLE') => {
  const {
    // eslint-disable-next-line camelcase
    list_category_comercial_feed,
    // eslint-disable-next-line camelcase
    list_category_core_used,
    // eslint-disable-next-line camelcase
    list_category_main_bulk_used,
    ...rest
  } = body;

  const data = {
    environment,
    ...rest,
  };
  return api.post(`/farms_details`, data);
};

const deleteFarmsDetails = (id) => {
  return api.delete(`/farms_details/${id}`);
};

export {
  fetchFarmsDetails,
  fetchFarmsDetailsbyCustomerId,
  fetchFarmsDetailsbyCustomerIdAndByHarvestId,
  registerFarmsDetails,
  changeFarmsDetailsbyId,
  deleteFarmsDetails,
};
