/* eslint-disable no-unused-vars */
import React, {useState, useEffect, useContext} from 'react';
import * as Yup from 'yup';
import './style.scss';
import Button from '@material-ui/core/Button';
import fileDownload from 'js-file-download';
import DataGrid from '../../components/DataGrid';
import GenerateForm from '../../components/GenerateForm';
import {createUpdated, fetchBenchmark, fetchIndicator, fetchLastUpdated, saveData} from '../../services/benchmark';
import {fetchCategoryLivestockSystem} from '../../services/categoryLivestockSystem';
import {fetchFilterLivestock} from '../../services/dashboards';
import {fetchFarms} from '../../services/farms';
import {fetchFarmsDetails} from '../../services/farmsDetails';
import {fetchHarvests} from '../../services/harvests';
import {DataContext} from '../../utils/DataProvider';
import {normalizeCollectionData} from '../../utils/utils';
import {FilterBox, H2, GridContainer} from './styled';
import {fetchProcessingReportsZip} from '../../services/processing';

const UpdateBenchMark = () => {
  const {state} = useContext(DataContext);
  const [status, setStatus] = useState(0);
  const [updatedBench, seUpdatedBench] = useState(0);
  const [lastUpdatedbyHarverst, setLastUpdatedByHarverst] = useState(0);
  const [optionsFilter, setOptionsFilter] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const ENVS = state.environment === 'dairy' ? 'DAIRY_CATTLE' : 'BEEF_CATTLE';

  const fetchData = async (h) => {
    setIsLoading(true);
    try {
      const response = await fetchProcessingReportsZip(
        h,
        'arraybuffer', // responseType,
        {
          Accept: 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9',
        },
      );

      fileDownload(
        response.data,
        `${`exportacao_relatorio_${new Intl.DateTimeFormat('pt-BR', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        }).format(new Date())}_.zip`}`,
      );
    } catch (e) {
      console.error(e);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    (async () => {
      const harvests = await fetchHarvests();

      setOptionsFilter((prev) => ({
        ...prev,
        harvests: harvests?.data?.resultList || [],
      }));
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (updatedBench?.harvest?.value) {
        const lastUpdated = await fetchLastUpdated({
          harvest_id: updatedBench?.harvest?.value,
          environment: ENVS,
        });
        setLastUpdatedByHarverst(lastUpdated?.data);
      }
    })();
  }, [updatedBench?.harvest?.value]);// eslint-disable-line
  return (
    <div className="p-3">
      <H2>Placar do Benchmarking</H2>
      <FilterBox className="filter-updated">
        <GenerateForm
          onSubmit={async (_, actions) => {
            // eslint-disable-next-line no-alert
            const confirm = window.confirm('Esta ação calculará todos os indicadores desta safra. Deseja continuar?');
            if (confirm) {
              setStatus(0);
              const resp = await createUpdated({
                harvest_id: updatedBench?.harvest?.value,
                user_id: state?.user?.id,
                environment: ENVS,
              });
              setStatus(resp?.data);
            }
            actions.setSubmitting(false);
          }}
          groupFields={[
            {
              name: '',
              fields: [
                {
                  label: 'Atualizar indicadores do Benchmarking',
                  name: 'harvest',
                  type: 'autocomplete',
                  hasPlaceholder: true,
                  placeholder: 'Selecione uma Safra',
                  value: updatedBench.harvest,
                  required: true,
                  validations: {
                    harvest: Yup.string().required('Campo Obrigatório'),
                  },
                  options: normalizeCollectionData(optionsFilter?.harvests || [], [
                    {alias: 'label', key: 'title'},
                    {alias: 'value', key: 'id'},
                  ]),
                  onChange: (newValue) => {
                    seUpdatedBench((prev) => ({...prev, harvest: newValue}));
                    setStatus(0);
                  },
                  columns: {
                    xs: 5,
                  },
                },
              ],
            },
          ]}
          settings={{
            button: {
              text: 'Atualizar',
              textSubmitting: 'Atualizando....',
            },
          }}
          onBack={() => {
            // dispatch({
            //   type: FILTER.ACTION.CLOSE,
            // });
          }}
        />
        <Button
          variant="contained"
          color="primary"
          style={{marginLeft: '10px', marginTop: '76px'}}
          disabled={!lastUpdatedbyHarverst}
          onClick={() => fetchData(updatedBench?.harvest?.value)}>
          Exportar
        </Button>
        {status === 'STARTED' && <p>Atualização em background Iniciada...</p>}
        {status === 'IN_PROGRESS' && <p>Existe uma atualização em andamento para esta Safra...</p>}
        {!status && lastUpdatedbyHarverst?.final_date && <p>Última atualização para a safra selecionada: {lastUpdatedbyHarverst?.final_date}</p>}
      </FilterBox>

      {isLoading && (
        <div className="overlay">
          <div className="spinner" />
        </div>
      )}
    </div>
  );
};

export default UpdateBenchMark;
