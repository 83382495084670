import React, {useRef, useState, useEffect} from 'react';
import styled, {keyframes} from 'styled-components';
import {slideOutRight, slideInRight} from 'react-animations';
import * as Yup from 'yup';
import './styles.scss';
import {normalizeCollectionData} from '../../../utils/utils';
import {useInitialRenderEffect} from '../../../utils/functions';
import {fetchCustomers} from '../../../services/customers';
import {fetchHarvestsFilter} from '../../../services/harvests';
import {fetchFarmsDetailsbyCustomerId} from '../../../services/farmsDetails';
import GenerateForm from '../../GenerateForm';
import Alert from '../../Notification';

const FilterExportationMilk = ({setCustomer, setHarvest, setFarmsList, setOpenFilter, setSubmitted}) => {
  const ref = useRef(null);
  const [slideOut, setSlideOut] = useState(false);

  const [customers, setCustomers] = useState([]);
  const [harvests, setHarvests] = useState([]);
  const [farms, setFarms] = useState([]);
  const [hasFarms, setHasFarms] = useState(true);

  const [dataForm, setDataForm] = useState({});
  const [disableFields, setDisableFields] = useState({
    customer: false,
    harvest: true,
    farm: true,
  });

  const loadCustomers = async () => {
    try {
      const {data} = await fetchCustomers({'order[name]': 'ASC', 'whereinmatch[profile]': 'DAIRY_CATTLE'});
      setCustomers(data.resultList || []);
    } catch (e) {
      console.error(e);
    }
  };

  const loadHarvests = async (params) => {
    try {
      const {data} = await fetchHarvestsFilter(params);
      setHarvests(data.resultList || []);
    } catch (e) {
      console.error(e);
    }
  };

  const loadFarms = async (id, params = {}) => {
    try {
      params = {...params, environment: 'DAIRY_CATTLE'};
      const {data} = await fetchFarmsDetailsbyCustomerId(id, params);
      const farmsDetail = data.resultList.filter((farmDetail) => farmDetail.name || farmDetail.farm.name).map((farmDetail) => farmDetail.farm);
      setFarms(farmsDetail || []);

      if (farmsDetail.length) {
        setHasFarms(true);
      } else {
        setHasFarms(false);
      }
    } catch (e) {
      console.error(e);
    }
  };

  useInitialRenderEffect(() => {
    loadCustomers();
  });

  useEffect(() => {
    if (dataForm.customer) {
      loadHarvests({
        customer_id: dataForm.customer && dataForm.customer ? dataForm.customer : '',
      });
    }
  }, [dataForm.customer]);

  useEffect(() => {
    if (dataForm.customer && dataForm.harvest) {
      loadFarms(dataForm.customer, {'wherein[harvest.id]': dataForm.harvest});
    }
  }, [dataForm.customer && dataForm.harvest]);// eslint-disable-line

  const groupFields = [
    {
      name: '',
      fields: [
        {
          label: 'Cliente',
          name: 'customer',
          type: 'autocomplete',
          hasPlaceholder: true,
          placeholder: 'Selecione o cliente',
          value: dataForm.customer || '',
          required: true,
          disabled: disableFields.customer,
          validations: {
            customer: Yup.string().required('Campo obrigatório'),
          },
          options: normalizeCollectionData(customers, [
            {alias: 'label', key: 'name'},
            {alias: 'value', key: 'id'},
          ]),
          onChange: (data) => {
            if (data) {
              setDataForm((prev) => Object.assign(prev, {customer: data.value}));
              setDisableFields({
                customer: false,
                harvest: false,
                farm: !dataForm.harvest,
              });
            } else {
              setDataForm((prev) => Object.assign(prev, {customer: ''}));
              setDisableFields({
                customer: false,
                harvest: true,
                farm: !dataForm.harvest,
              });
            }
          },
          columns: {
            xs: 12,
          },
        },
        {
          label: 'Safra',
          name: 'harvest',
          type: 'autocomplete',
          hasPlaceholder: true,
          placeholder: 'Selecione a safra',
          value: harvests.length ? dataForm.harvest : '',
          required: true,
          disabled: disableFields.harvest,
          validations: {
            harvest: Yup.string().required('Campo obrigatório'),
          },
          onChange: (data) => {
            if (data) {
              setDataForm((prev) => Object.assign(prev, {harvest: data.value}));
              setDisableFields({
                customer: false,
                harvest: false,
                farm: false,
              });
            } else {
              setDataForm((prev) => Object.assign(prev, {harvest: ''}));
              setDisableFields({
                customer: false,
                harvest: false,
                farm: true,
              });
            }
          },
          options: normalizeCollectionData(harvests, [
            {alias: 'label', key: 'title'},
            {alias: 'value', key: 'id'},
          ]),
          columns: {
            xs: 12,
          },
        },
        {
          hideField: false,
          label: 'Fazenda',
          name: 'farm',
          type: 'autocompleteMultiple',
          hasPlaceholder: true,
          placeholder: 'Selecione a fazenda',
          value: dataForm.farm,
          key: dataForm.customer || 'farm',
          required: true,
          validations: {
            farm: Yup.string().required('Campo obrigatório'),
          },
          disabled: !hasFarms || !dataForm.customer || !dataForm.harvest,
          onChange: (data) => {
            if (data) {
              setDataForm((prev) => Object.assign(prev, {farm: data.map((item) => item.value)}));
              setDisableFields({
                customer: false,
                harvest: false,
                farm: false,
              });
            } else {
              setDataForm((prev) => Object.assign(prev, {farm: ''}));
              setDisableFields({
                customer: false,
                harvest: false,
                farm: false,
              });
            }
          },

          options: normalizeCollectionData(farms, [
            {alias: 'label', key: 'name'},
            {alias: 'value', key: 'id'},
          ]),
          columns: {
            xs: 12,
          },
        },
      ],
    },
  ];

  const onSubmit = async (values, actions) => {
    Object.keys(values).map((item) => {
      if (values[item] && values[item].value) {
        return Object.assign(values, {[item]: values[item].value});
      }
      return true;
    });

    try {
      actions.setSubmitting(true);
      const customerFound = customers.filter((customer) => customer.id === values.customer) || [];
      const harvestFound = harvests.filter((harvest) => harvest.id === values.harvest) || [];

      setSlideOut((prev) => !prev);
      setCustomer(customerFound[0] || {});
      setHarvest(harvestFound[0] || {});
      setFarmsList(dataForm.farm || []);
      actions.setSubmitting(false);
      setOpenFilter(false);
      setSubmitted(true);
    } catch (e) {
      console.error(e);
      actions.setSubmitting(false);
    }
  };

  return (
    <>
      {!hasFarms && <Alert type="warning" message="Nenhuma fazenda caracterizada para essa safra." time={4000} />}
      <Card className={`row filter-container ${slideOut ? 'slideOut' : ''}`} style={{transform: 'translateX(0)'}} ref={ref}>
        <div className="col-6 offset-3 filter-content">
          <GenerateForm onSubmit={onSubmit} groupFields={groupFields} />
        </div>
      </Card>
    </>
  );
};

const SlideOut = keyframes`${slideOutRight}`;
const SlideIn = keyframes`${slideInRight}`;
const Card = styled.div`
  div {
    font-size: 12px;
    font-weight: 200;
  }
  label {
    font-size: 13px;
  }
  button {
    font-size: 14px;
  }
  animation: 1s ${SlideIn};
  &.slideOut {
    animation: 1s ${SlideOut};
  }
`;

export default FilterExportationMilk;
